<template>
  <div>

    <div class="row mt-3 mb-3">
      <div class="col text-center">
        Showing open offers across the site.
      </div>
    </div>

    <div class="row">
      <div class="col text-center">
        <loading-spinner v-if="isLoading"></loading-spinner>
      </div>
    </div>

    <div class="row" v-if="openOffers.length === 0 && !isLoading">
      <div class="col text-center">
        <code>No outstanding offers</code>
      </div>
    </div>

    <table class="table table-borderless" v-if="openOffers.length > 0">
      <tbody>
      <tr v-for="offer in openOffers" :key="offer.edition">
        <td class="align-middle" width="25%" v-if="offer.edition">
          <router-link
            :to="{ name: 'confirmPurchaseSimple', params: { editionNumber: parseInt(offer.edition) }}">
            <edition-image class="img-thumbnail"
                           :edition="findEdition(parseInt(offer.edition))"
                           :full="true"/>
          </router-link>
        </td>
        <td class="align-middle">
          <code>Offer</code>
          <br/>
          <price-in-eth :value="offer.highestBid"></price-in-eth>
          <span v-if="currentUsdPrice"><usd-price :price-in-ether="offer.highestBid"></usd-price></span>
        </td>
        <td class="align-middle">
          <span class="text-muted small">User:</span>
          <clickable-address :eth-address="offer.highestBidder" class="small"></clickable-address>
          <div v-if="isArtist(offer) && isNotSoldOut(offer)" title="Accept the offer">
            <accepting-bid-flow :auction="offer"></accepting-bid-flow>
          </div>
          <div v-if="isBidder(offer)" title="Withdraw the offer">
            <withdrawing-bid-flow :auction="offer"></withdrawing-bid-flow>
          </div>
          <div v-if="isArtist(offer)" title="Reject the offer">
            <reject-bid-flow :auction="offer"></reject-bid-flow>
          </div>
          <div v-if="isKo" title="Close the auction">
            <cancelling-auction-flow :auction="offer"></cancelling-auction-flow>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>
<script>

  import * as _ from 'lodash';
  import { mapGetters, mapState } from 'vuex';
  import ClickableAddress from '../components/generic/ClickableAddress';
  import * as actions from '../store/actions';
  import EditionImage from '../components/generic/EditionImage';
  import PriceInEth from '../components/generic/PriceInEth';
  import UsdPrice from '../components/generic/USDPrice';
  import WithdrawingBidFlow from '../components/auction/control-flows/WithdrawingBidFlow';
  import AcceptingBidFlow from '../components/auction/control-flows/AcceptingBidFlow';
  import CancellingAuctionFlow from '../components/auction/control-flows/CancelAuctionFlow';
  import RejectBidFlow from '../components/auction/control-flows/RejectBidFlow';
  import LoadingSpinner from '../components/generic/LoadingSpinner';

  export default {
    name: 'openOffers',
    components: {
      LoadingSpinner,
      RejectBidFlow,
      CancellingAuctionFlow,
      AcceptingBidFlow,
      WithdrawingBidFlow,
      UsdPrice,
      ClickableAddress,
      EditionImage,
      PriceInEth,
    },
    data() {
      return {
        isLoading: false,
        openOffers: []
      };
    },
    computed: {
      ...mapState([
        'account',
        'currentUsdPrice',
        'auctionsService',
      ]),
      ...mapGetters('kodaV2', [
        'findEdition'
      ]),
      ...mapState('auction', [
        'owner',
      ]),
      isKo() {
        if (!this.account || !this.owner) {
          return false;
        }
        return this.owner === this.account;
      },
    },
    methods: {
      isBidder(offer) {
        if (!this.account) {
          return false;
        }
        return offer.highestBidder === this.account;
      },
      isArtist(offer) {
        if (this.isKo) {
          return true;
        }
        if (!this.account || !offer.controller) {
          return false;
        }
        return offer.controller === this.account;
      },
      isNotSoldOut(offer) {
        const edition = this.findEdition(parseInt(offer.edition));
        if (!edition) {
          return false;
        }
        return (edition.totalAvailable - edition.totalSupply) > 0;
      }
    },
    created() {

      const loadOpenOffers = () => {
        this.isLoading = true;
        this.auctionsService.getOpenAuctions()
          .then((results) => {
            const {data} = results;
            const editions = _.map(data, 'edition');
            this.openOffers = data;
            this.$store.dispatch(`kodaV2/${actions.LOAD_EDITIONS}`, editions);
          })
          .finally(() => {
            this.isLoading = false;
          });
      };

      this.$store.watch(
        () => this.auctionsService.currentNetworkId,
        () => loadOpenOffers()
      );

      if (this.auctionsService) {
        loadOpenOffers();
      }

      this.$store.subscribeAction((action) => {
        if (action.type === `auction/${actions.REFRESH_OPEN_OFFERS}`) {
          loadOpenOffers();
        }
      });

    }
  };
</script>


<style scoped lang="scss">
  @import '../ko-colours.scss';

  .full-banner {
    p {
      margin-bottom: 0;
    }
  }

  /* mobile only */
  @media screen and (max-width: 767px) {
  }

  .img-thumbnail {
    max-width: 200px;
  }

</style>
